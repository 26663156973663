@import '../OrlandoVariables.scss';

#event-details-container {
  #event-details-list {
    li {
      margin-bottom: 15px;
    }
  }

  .event-detail-card {
    p, div, label {
      color: $color-text-dark-darker;
    }

    h4 { margin: 0; }

    > div {
      padding-left: 20px;
      padding-right: 20px;
    }

    &--header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      min-height: 140px;

      p, div {
        color: #fff;
      }

      &--top {
        display: flex;
        justify-content: flex-end;
        padding: 15px 0;

        .tiny-link-alt {
          color: $color-text-light-darkest;

          &:hover {
            color: #fff;
            text-decoration: underline;
          }
        }
      }

      &--main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px 0;

        @media only screen and (max-width: 400px) {
          flex-direction: column;
        }
      }

      &--left {
        @media only screen and (max-width: 400px) {
          align-self: flex-start;
        }

        h3 {
          margin-bottom: 8px;
        }
        h4 {
          @include small-uppercase;
          font-size: 12px;
        }
      }

      &--right {
        align-self: flex-end;

        @media only screen and (max-width: 400px) {
          align-self: flex-start;
          margin-top: 5px;
        }
      }

      &--time {
        @include small-uppercase;
        font-size: 16px;
      }
    }

    &--overview {
      background: $gray-lightest;
      padding: 15px 0;

      p {
        margin: 0;
      }
    }

    &--main {
      background: #fff;
      padding-top: 20px;

      .label {
        color: $color-text-dark-lighter;
      }
    }
  }
}
