@import "../OrlandoVariables.scss";

#book-exchange-container {
  .book-exchange-card {
    margin-top: 20px;
    padding-bottom: 30px;
    background: $gray-lightest;

    h2 {
      color: $color-text-dark-darker !important;
      margin-top: 25px !important;
    }

    p,
    ol,
    ul {
      color: $color-text-dark !important;
      line-height: 21px !important;
    }

    ol {
      li {
        margin-bottom: 8px;
      }
    }

    blockquote {
      color: $color-text-dark-lighter;
      font-style: italic;
      font-weight: normal !important;

      .by-line {
        color: lighten($color-text-dark-lighter, 5%);
      }
    }

    > div {
      padding-left: 30px;
      padding-right: 30px;
    }

    &--header {
      height: 300px !important;
    }
  }
}
