@import '../OrlandoVariables.scss';

#login-form {
  $container-height: $input-height-lg + ($input-border-width-lg * 2);

  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;

  &--inner {
    width: 100%;
    height: $container-height;
    box-sizing: border-box;
    border: $input-border-width-lg solid #fff;
    border-radius: $container-height / 2;

    @media screen and (min-width: 901px) {
      width: $content-width;
    }
  }

  &--name {
    position: relative;
    z-index: 10;
    border: none;
    width: 100%;
    height: $input-height-lg;
    padding-left: 30px - $input-border-width-lg;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 22px;
    color: #fff;
    background: transparent;

    &::placeholder {
      color: rgba(#fff, 0.6);
    }

    &:focus {
      outline: none;
      border-color: #fff;
    }
  }
}