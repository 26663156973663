@import '../OrlandoVariables';

.registry-card {
  background: $gray-lightest;
  // text-align: center;
  margin-bottom: 20px;

  * {
    user-select: none;
  }

  &--title {
    color: $color-text-dark-darker !important;
    padding: 12px 20px;
  }

  &--image {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 100px;
    margin: 15px 50px 25px;
  }

  &--cta {
    padding: 12px 20px;
    background: $color-primary;

    .label {
      color: #fff !important;
    }
  }
}
