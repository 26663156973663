@import "../OrlandoVariables.scss";

#the-day-of-container {
  .the-day-of-card {
    margin-top: 20px;
    padding-bottom: 30px;
    background: $gray-lightest;

    h2 {
      color: $color-text-dark-darker !important;
      margin-top: 25px !important;
    }

    h3 {
      color: $color-text-dark !important;
      margin-bottom: 5px !important;
      margin-top: 20px;
      font-size: 16px;
    }

    p {
      color: $color-text-dark !important;
      margin-top: 0;
    }

    > div {
      padding-left: 30px;
      padding-right: 30px;
    }

    &--header {
      height: 300px !important;
    }
  }

  button {
    @include small-uppercase;
    text-align: left;
    border: none;
    background-color: $color-primary !important;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

    &:focus {
      outline: none;
    }

    span {
      margin-bottom: 0;
      color: #fff;
      text-decoration: underline !important;
    }
  }
}
