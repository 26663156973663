@import "./OrlandoVariables.scss";

#orlando {
  &-container {
    @include normal-text;
    position: relative;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    height: 100vh;
    overflow: auto;
    padding-top: 50px;

    @media screen and (max-width: 900px) {
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }

    h1,
    .h1 {
      @include h1;
    }

    h2,
    .h2 {
      @include h2;
      @include page-title;
    }

    h3,
    .h3 {
      @include h3;
    }

    .h2-small {
      @include h2;
      font-size: 40px;
      line-height: 40px;
    }

    .h3-alt {
      @include h3-alt;
    }

    div,
    p,
    a {
      @include normal-text;
    }

    p {
      line-height: 21px;
    }

    input {
      &.invalid {
        border-color: $color-danger !important;
      }
    }

    .card {
      overflow: hidden;
      border-radius: 25px;

      &--header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 140px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;

        p,
        div {
          color: #fff;
        }
      }

      a {
        color: $color-text-dark-darker;
      }
    }

    .map {
      &--header,
      &--footer {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 20px;
        background: $color-primary;

        .label {
          margin-bottom: 0;
          color: #fff;
        }
      }
    }

    .tiny-link {
      @include normal-text;
      font-size: 12px;
      line-height: 12px;
      text-decoration: underline;
      background: transparent;
      border: none;
      box-shadow: none;

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }

    .tiny-link-alt {
      @extend .tiny-link;
      @include small-uppercase;
      font-size: 10px;
      line-height: 10px;
    }

    .label {
      @include small-uppercase;
      color: $color-text-dark;
      margin-bottom: 5px;
    }

    .label.error-message {
      color: darken($color-danger, 10%);
      margin-bottom: 0;
      margin-top: 5px;
    }

    .label-group {
      padding-bottom: 15px;

      .value {
        p {
          line-height: 15px;
          margin: 0;
          padding-bottom: 6px;
        }
      }
    }

    .dark-text-shadow {
      text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    }
  }

  &-inner {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-masthead {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    user-select: none;
    // transform: translateX(-326px);
    width: 100%;
    left: 0;

    @media screen and (min-width: 901px) {
      padding-left: 42px;
      padding-right: 42px;
      margin-top: 270px;
    }

    @media screen and (max-width: 900px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    @include background-blur-transition;
    &:not(.is-focused) {
      @include background-blur;
    }

    .nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 140px;

      @media screen and (max-width: 900px) {
        margin-bottom: 30px;
        margin-top: 20px;
      }

      @media screen and (min-width: 901px) {
        position: absolute;
        top: 21px;
        left: 405px;
        margin-bottom: 0;
        margin-left: 0;
        height: 200px;
      }

      &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding: 8px 15px;
        border-radius: 14px;
        user-select: none;
        transition: background-color 150ms;
        background-color: transparent;

        @media screen and (max-width: 900px) {
          width: 100%;
          border-radius: 18px;
        }

        &:not(:last-child) {
          margin-bottom: 5px;

          @media screen and (max-width: 900px) {
            margin-bottom: 10px;
          }
        }

        &--text {
          @include small-uppercase;

          @media screen and (max-width: 900px) {
            font-size: 14px;
            text-align: center;
          }
        }

        &:hover,
        &.active, &:active
        // &.active:not(.nav-is-hovered),
        // &:active:not(.nav-is-hovered)
        {
          outline: none;

          background-color: $color-primary;
        }
      }
    }

    h1 {
      position: relative;
      margin: 0;

      @media screen and (max-width: 900px) {
        position: relative;
        top: auto;
        left: auto;
        margin-top: 16px;
        margin-bottom: 22px;
      }

      @media screen and (max-width: 600px) {
        font-size: 100px;
        line-height: 100px;
        margin-bottom: 54px;
      }

      @media screen and (max-width: 400px) {
        font-size: 80px;
        line-height: 80px;
      }

      #orlando-date {
        position: absolute;
        top: 25px;
        left: 222px;
        display: inline-block;
        font-size: 15px;
        line-height: 15px;
        text-transform: uppercase;
        font-weight: bold;
        font-family: "Montserrat", Helvetica, Arial, sans-serif;
        letter-spacing: 2px;

        @media screen and (max-width: 600px) {
          top: auto;
          bottom: -20px;
          left: 0;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  &-logo {
    width: 100%;
    max-width: 370px;
  }

  &-logo-container {
    position: relative;
    @media screen and (min-width: 901px) {
      height: 0;
      overflow: visible;
      transform: translate(-334px, -250px);
    }
  }

  &-routes {
    width: 100%;
    max-width: $content-width;
    padding-bottom: 150px;

    @media screen and (min-width: 901px) {
      margin-top: 40px;
    }
  }

  &-modals {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &-announcement-banner {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background: $gray-lightest;

    &-inner {
      box-sizing: border-box;
      padding: 10px 0;
      text-align: center;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      color: $color-text-dark-darker !important;
      font-weight: normal !important;
      line-height: 20px;

      a {
        color: #111 !important;
        text-decoration: underline;
      }
    }
  }

  &-footer {
    position: fixed;
    bottom: 0;
    width: 100%;

    p {
      text-align: center;
      font-size: 11px;
      opacity: 0.4;
    }

    strong {
      opacity: 0.7;
    }
  }
}

label,
label * {
  user-select: none;
}
