@import '../OrlandoVariables.scss';

$rsvp-pill-button-width: 104px;
@function rsvp-width-less($less-width: 0px) {
  $total-less-width: $rsvp-pill-button-width + $less-width;
  @return calc(100% - #{$total-less-width});
}
$rsvp-width: rsvp-width-less(0);

@mixin rsvp-pill(
  $body-bg-color: #fff,
  $body-text-color: $color-text-dark,
  $button-color: #fff,
) {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  height: 50px;
  width: 100%;
  border-radius: 25px;
  margin-bottom: 10px;

  &:hover,
  &.is-active {
    .button,
    .rsvp {
      transition-delay: 0ms !important;
    }
  }

  &:hover {
    .button {
      width: $rsvp-pill-button-width + 10px;
    }
    .rsvp {
      width: rsvp-width-less(10px);
    }
  }

  &.is-active {
    .button {
      width: 100%;
      background-position-x: -20px;

      &--text {
        opacity: 0;
      }
    }

    .rsvp {
      width: 0;

      &--title {
        opacity: 0;
      }
    }
  }
  
  .rsvp {
    position: absolute;
    height: 50px;
    width: $rsvp-width;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    background-color: $body-bg-color;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    &--title {
      transition: opacity 200ms;
      white-space: nowrap;
      color: $body-text-color;
      font-weight: bold;
      font-size: 16px;
    }

    &--subtitle {
      color: $color-text-dark-lighter;
      font-size: 10px;
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      margin-left: 6px;
    }

    .tiny-link,
    .tiny-link-alt {
      margin-left: 10px;

      @media screen and (max-width: 600px) {
        margin-left: 32px;
        padding-left: 0;        
      }
    }
  }

  .button {
    position: absolute;
    right: 0;
    background-image: url('../../../../assets/thats-me-btn-bg-4x.png');
    background-color: transparent;
    background-position: 0 0;
    background-size: auto 50px;
    background-repeat: no-repeat;
    cursor: pointer;
    border: none;
    box-shadow: none;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    height: 50px;
    width: $rsvp-pill-button-width;
    padding: 0;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: $button-color;

    &--text {
      transition: all 200ms;
    }

    &:focus {
      outline: none;
    }
  }

  .button,
  .rsvp {
    transition: all 200ms;
    transition-delay: 200ms;
  }
}

#rsvp {
  $width: $content-width;

  &-route {
    @include background-blur-transition;
    &.rsvp-is-active {
      @include background-blur;
    }
  }

  &-search {

    &--form {
      $container-height: $input-height-lg + ($input-border-width-lg * 2);
  
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      width: 100%;
  
      &--inner {
        width: 100%;
        height: $container-height;
        box-sizing: border-box;
        border: $input-border-width-lg solid #fff;
        border-radius: $container-height / 2;
    
        @media screen and (min-width: 901px) {
          width: $width;
        }
      }
  
      &--name {
        position: relative;
        z-index: 10;
        border: none;
        width: 100%;
        height: $input-height-lg;
        padding-left: 30px - $input-border-width-lg;
        font-family: 'Roboto', Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 22px;
        color: #fff;
        background: transparent;

        text-align: center;
        padding-right: 30px - $input-border-width-lg;
  
        &::placeholder {
          color: rgba(#fff, 0.6);
        }
  
        &:focus {
          outline: none;
          border-color: #fff;
        }
      }
    }
  
    &--results {
      width: 100%;
      padding: 0;
      margin: 20px 0 0;
      box-sizing: border-box;
      list-style: none;
  
      @media screen and (min-width: 901px) {
        padding: 0 32px;
      }
  
      li {
        cursor: pointer;
        @include rsvp-pill(#fff, $color-text-dark, #fff);
        @media screen and (min-width: 901px) {
          width: 396px;
        }
      }
    }
  }

  &-thank-you {
    &--title {
      text-align: center;
    }
    &--pill {
      margin-top: 20px;
      @include rsvp-pill($color-primary, #fff, $color-primary);

      .button {
        color: $color-primary;
        background-image: url('../../../../assets/edit-rsvp-btn-bg-4x.png');
      }

      .tiny-link {
        opacity: 0.8;
      }
    }
  }
}
