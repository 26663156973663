@import './BangaloreVariables.scss';

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.app-container {
  width: 100%;
}

.bangalore-container {
  position: relative;
  background-image: url('../../../assets/bg.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow-y: scroll;

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0));
  }
}

.bangalore-inner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  display: flex;

  @media (max-width: 940px) {
    background-position: right top;
  }

  .column {
    &-container {
      width: 100%;
      color: #fff;
      
      &.left {
        background-color: $gray-dark-sheer;
        max-width: 520px;
      }

      &.right {
        min-width: 600px;
      }
    }

    &-inner {
      padding: 30px 50px 50px;
      transition: 300ms padding;

      @media (max-width: 767px) {
        &.left { text-align: left !important; }
        padding-right: 25px;
        padding-left: 25px;
      }

      p {
        margin: 0;
        padding: 0 0 15px 0;
        font-family: 'Montserrat', Helvetica, Arial, sans-serif;
        font-size: 12px;
        font-weight: normal;
        line-height: 20px;
        text-transform: uppercase;

        &.display {
          font-family: 'Lobster', Helvetica, Arial, sans-serif;
          text-transform: none;

          &.large {
            font-size: 30px;
            padding-top: 3px;
          }
        }

        &.subtitle {
          letter-spacing: 3px;
          padding-bottom: 20px;
        }
      }

      h1 {
        font-family: 'Lobster', Helvetica, Arial, sans-serif;
        font-size: 70px;
        margin-bottom: 20px;
      }

      section {
        .subsection {
          margin-bottom: 25px;
        }
      }

      .small-br {
        display: block;
        height: 10px;
      }

      &.left {
        text-align: right;

        .right-in-left {
          margin-top: -35px;

          + hr {
            margin-top: 40px;
          }
        }
      }

      &.right {
        p {
          font-weight: bold;
        }

        .small-br {
          height: 20px;
        }

        section {
          > h1 {
            text-shadow: 1px 2px 5px rgba(0,0,0,0.5)
          }

          > p {
            text-shadow: 0px 1px 2px rgba(0,0,0,0.4)
          }

          &.day-two {
            margin-top: 465px;
          }
          &.day-one {
            margin-top: 406px;
          }
        }
      }

    }
  }

  button {
    border: none;
    box-shadow: none;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    cursor: pointer;
    font-size: 12px;

    &:not(.style-like-link) {
      display: flex;
      justify-content: space-between;
      height: 50px;
      width: 100%;
      line-height: 20px;
      padding: 0px 20px;
    }

    &.style-like-link {
      font-size: 10px;
      text-decoration: underline;
      background: transparent;
      padding: 0;
  
      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline: none;
      }

      .big-plus {
        display: inline-block;
        font-size: 30px;
        font-weight: lighter;
        text-decoration: none;
        vertical-align: middle;
      }

      .big-minus {
        display: inline-block;
        text-decoration: none;
      }
    }

    i {
      font-size: 20px;
    }
  }

  .rsvp {
    &-button {
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
      color: #fff;

      &:focus {
        border: none;
        outline: none;
      }
    }
    &-form {
      &-backdrop {
        background-color: $gray-dark-sheer-lighter;
        animation: 300ms fade-in;

        &.fading-out {
          transition: opacity 300ms;
          opacity: 0;
        }
      }

      &-container {
        padding-top: 10px;
      }

      &-interactive {
        overflow: hidden;
        background-position: 0 0;
        background-size: 300px auto;
        background-repeat: repeat;
        width: 300px;
        padding: 20px;
        // transition: all 300ms;

        @media (max-width: 400px) {
          width: calc(100% - 40px);
        }
      }

      &-form {
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
        font-size: 12px;
        text-transform: uppercase;

        fieldset {
          border: none;
          margin: 0;
          padding: 0 0 20px;

          legend {
            font-family: 'Montserrat', Helvetica, Arial, sans-serif;
            font-weight: bold;
            padding-bottom: 15px;
          }

        }

        .input-group {
          display: flex;
          align-items: center;
          padding-bottom: 10px;

          label {
            padding-left: 10px;
            font-weight: normal;
          }
        }

        button {
          &.style-like-link {
            color: #fff;
          }

          // Submit
          &:not(.style-like-link) {
            color: $gold;

            &[disabled] {
              opacity: 0.6;
            }
          }
        }
      }

      &-invitee {
        &-container:not(:first-child) {
          padding-top: 10px;
        }

        display: flex;
        flex-wrap: wrap;

        @media (max-width: 400px) {
          display: block;
        }

        .input-group {
          flex-basis: 50%;
          flex-grow: 1;

          &:first-of-type {
            padding-right: 10px;
          }

          @media (max-width: 400px) {
            width: 100%;
            flex-basis: 100%;
            box-sizing: border-box;

            &:not(:first-of-type) {
              margin-top: 5px;
            }
          }
        }

        input {
          height: 30px;
          width: 100%;
          padding: 0 0 0 10px;
          margin: 0;
          border: 0;
          text-transform: none;
          color: $gray-dark;
        }
      }
    }
  }

  small {
    font-size: 8px;
  }
  
  a {
    color: $color-link;
    text-decoration: none;
    
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }

    &:visited {
      color: darken($color-link, 3%);
    }
  }

  hr {
    border-style: solid;
    border-width: 1px;
    color: rgba(213, 216, 210, 0.5);
    margin: 0 0 40px;
  }

  @media (max-width: 767px) {
    .break-mobile {
      display: block;
    }
  }
}
