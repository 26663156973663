// Colors.
$gray: #656b6b;
$gray-lightest: #ecf1f1;
$gray-light: #c5c7c7;
// Theme.
$color-primary: #00beb1;
$color-danger: #d74b4b;
// Text.
$color-text-light: #fff;
$color-text-light-darker: $gray-lightest;
$color-text-light-darkest: $gray-light;
$color-text-dark-lighter: #898d8d;
$color-text-dark: #686c66;
$color-text-dark-darker: #414444;
// Layout.
$content-width: 460px;
$input-height-lg: 82px;
$input-border-width-lg: 3px;

// Typography mixins.
@mixin normal-text {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: $color-text-light;
}

@mixin small-uppercase {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.25px;
  color: $color-text-light;
  text-transform: uppercase;
}

@mixin heading {
  font-family: "Lobster", serif;
  font-weight: normal;
  color: $color-text-light;
  margin: 0;
}

@mixin h1 {
  @include heading;
  font-size: 150px;
  line-height: 150px;
}

@mixin h2 {
  @include heading;
  font-size: 52px;
  line-height: 52px;
}

@mixin h3 {
  @include normal-text;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

@mixin h3-alt {
  @include heading;
  font-size: 32px;
  line-height: 32px;
}

@mixin page-title {
  margin-bottom: 20px;
}

@mixin orlando-route-layout($max-width) {
  $top-pos: 450px;
  $left-pos: 282px;

  width: 100%;
  max-width: $max-width;
  margin: 0 auto;

  @media screen and (min-width: 901px) {
    position: absolute;
    top: 0;
    transform: translateY($top-pos);
    left: $left-pos;
  }
}

@mixin background-blur-transition {
  transition: all 200ms linear;
}
@mixin background-blur {
  transition-delay: 200ms;
  opacity: 0.05;
  // Modernizr (should be .cssfilters but that doesn't come with the CDN build).
  .cssanimations & {
    filter: blur(14px);
    opacity: 0.6;
  }
}
