@import "../OrlandoVariables.scss";

#things-to-do-container {
  #things-to-do-list {
    li {
      margin-bottom: 15px;
    }
  }

  .thing-to-do-card {
    p,
    div,
    label,
    a {
      color: $color-text-dark-darker;
    }

    > div {
      padding-left: 20px;
      padding-right: 20px;
    }

    &--header {
      &--top {
        display: flex;
        align-items: center;
        padding: 15px 0;

        .tiny-link-alt {
          margin-left: auto;
          color: $color-text-light-darkest;

          &:hover {
            color: #fff;
            text-decoration: underline;
          }

          @media only screen and (max-width: 400px) {
            display: none;
          }
        }
      }

      &--recommended {
        display: flex;
        align-items: center;
        margin-bottom: 0 !important;

        svg {
          color: $color-primary;
          width: 12px;
          height: 12px;
        }
      }

      &--main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
      }

      &--dollars {
        @include small-uppercase;
        font-size: 16px;
      }
    }

    &--description {
      background: $gray-lightest;
      padding: 15px 0;

      p {
        margin: 0;
      }
    }

    &--main {
      background: #fff;
      padding-top: 20px;

      .label {
        color: $color-text-dark-lighter;
      }
    }
  }
}
