@import "../OrlandoVariables.scss";

#orlando-container {
  #active-rsvp {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 180px;

    transition: all 100ms linear;
    opacity: 0;

    @media screen and (max-width: 900px) {
      padding-top: 80px;
    }

    &.in-full-view {
      opacity: 1;
    }

    &--container {
      width: 100%;
      max-width: 440px;
      min-width: 300px;
      box-sizing: content-box;
      padding: 0 30px 100px;

      @media screen and (max-width: 900px) {
        padding: 0 15px 50px;
      }
    }

    &--close-btn {
      @include small-uppercase;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: transparent !important;
      border: none !important;
      box-shadow: none;
      padding: 10px 0;

      &:focus {
        outline: none;
      }

      &--icon {
        font-size: 20px;
        margin-right: 8px;
      }

      &--text {
        transform: translateY(1px);
      }
    }

    &--title {
      margin: 5px 0 10px;

      small {
        @include small-uppercase;
        margin-left: 6px;
      }
    }

    &--form {
      &--errors {
        @include small-uppercase;
        line-height: 14px;
        margin-bottom: 15px;
        background-color: #d45;
        color: #fff;
        padding: 10px 0 10px 20px;
        border-radius: 20px;
      }

      &--comments {
        padding: 20px;
        background: #fff;

        .label {
          display: block;
          margin-bottom: 5px;
        }

        textarea {
          min-height: 100px;
        }
      }

      &--submit {
        transition: opacity 200ms;
        margin-top: 15px;

        button {
          @include small-uppercase;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          cursor: pointer;
          height: 60px;
          width: 100%;
          border-radius: 30px;
          border: none;
          padding: 0 20px;
          background-color: $color-primary;
          color: white;
          font-size: 14px;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

          &:not(:hover) {
            span.arrow {
              transform: translateX(-10px);
            }
          }

          span {
            line-height: 22px;

            &.arrow {
              transition: transform 200ms ease-out;
              font-size: 22px;
              font-weight: bold;
            }
          }
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="date"],
      textarea {
        box-sizing: border-box;
        width: 100%;
        height: 34px;
        padding-left: 5px;
        font-size: 14px;
        color: $color-text-dark-darker;
        border: 2px solid $gray-light;

        &::placeholder {
          color: $color-text-dark-lighter;
        }
      }

      textarea {
        padding: 8px 5px;
        margin: 0;
      }

      .radio-group {
        display: inline-flex;
        margin-right: 15px;

        span {
          color: $color-text-dark-darker;
          margin-left: 8px;
        }

        a {
          text-decoration: underline;
          color: lighten($color-text-dark-darker, 10%);
        }
      }
    }

    .invitee-card {
      background-color: #fff;
      border-radius: 25px;
      overflow-y: hidden;
      color: $color-text-dark;
      margin-bottom: 10px;

      h3,
      h4,
      &--header--right label {
        color: $color-text-dark-darker;
      }

      &--header {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // align-items: center;
        background-color: $gray-lightest;
        padding: 14px 20px;

        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &--left,
        &--right div {
          display: flex;
          height: 100%;
          align-items: center;
        }

        &--right div {
          // flex-basis: 150px;
          // justify-content: flex-end;
          margin-top: 8px;

          // @media screen and (max-width: 600px) {
          //   flex-basis: auto;
          //   justify-content: flex-start;
          //   margin-top: 8px;
          // }
        }
      }

      &--name {
        h3 {
          margin-top: 0;
          margin-bottom: 0;
        }

        label {
          @include small-uppercase;
          color: $color-text-dark;

          input {
            margin-right: 6px;
          }
          span {
            margin-right: 15px;
          }
        }
      }

      &--name-inputs {
        display: flex;

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="date"] {
          font-size: 20px;
          font-weight: bold;

          &:focus {
            border: 2px solid $color-primary;
          }
        }
      }

      &--name-inputs input::placeholder,
      &--name-placeholders {
        font-size: 20px;
        font-weight: bold;
        color: $color-text-dark-lighter;
        font-style: italic;
      }

      &--first-name-input,
      &--last-name-input {
        width: 50%;
      }

      &--first-name-input {
        margin-right: 6px;
      }

      &--body,
      &--body--before {
        padding: 20px;

        fieldset:not(:last-child),
        section:not(:last-child) {
          margin-bottom: 18px;

          &.extra-padding-y {
            &:not(:last-child) {
              margin-bottom: 23px !important;
            }
            &:not(:first-child) {
              margin-top: 23px !important;
            }
          }
        }

        .error-message {
          display: block;
          width: 100%;
          font-size: 10px;
        }

        &--dietary,
        &--contact,
        &--vaccinated {
          display: flex;
          justify-content: space-between;

          @media screen and (max-width: 600px) {
            flex-direction: column;

            .input-group:not(:last-child) {
              margin-bottom: 18px;
            }
          }
        }

        &--contact {
          .input-group {
            flex-basis: calc(50% - 9px);
          }
        }

        &--dietary,
        &--vaccinated {
          .input-group {
            flex-basis: 100%;
          }
        }

        legend,
        .legend,
        p {
          display: block;
          margin-top: 0;
          margin-bottom: 7px;
        }

        legend,
        .legend {
          @include small-uppercase;
          color: $color-text-dark-darker;
        }

        p {
          font-size: 14px;
          color: $color-text-dark-lighter;
          font-weight: normal;
        }

        .event-attending-pill,
        .hotel-pill,
        .pill {
          display: flex;
          justify-content: space-between;
          background-color: $gray-lightest;
          margin-bottom: 4px;
          border-radius: 50px;
          padding: 11px 20px 11px 15px;

          @media screen and (max-width: 600px) {
            flex-direction: column;
          }

          label {
            cursor: pointer;
            display: flex;
            align-items: center;
          }

          &--somewhere-else-not-sure {
            span {
              opacity: 0.8;
            }
          }

          &--title {
            color: $color-text-dark-darker;
            font-weight: bold;
            font-size: 16px;
          }

          &--label-text {
            display: flex;
            flex-direction: column;
          }

          &--subtitle {
            @include small-uppercase;
            color: $color-text-dark;
            font-size: 10px;

            @media screen and (max-width: 600px) {
              margin-top: 5px;
            }
          }

          &--checkbox {
            margin-right: 10px;
          }

          &--date-time,
          &--link {
            @include small-uppercase;
            display: flex;
            align-items: center;
            margin: 0;
            color: $color-text-dark;
            font-size: 10px;

            @media screen and (max-width: 600px) {
              margin-left: 22px;
            }
          }

          &--link {
            color: $color-primary;
            text-decoration: underline;

            @media screen and (max-width: 600px) {
              display: none;
            }
          }
        }

        button {
          text-align: left;
          border: none;
          background-color: $color-primary !important;
          cursor: pointer;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

          &:focus {
            outline: none;
          }

          span {
            margin-bottom: 0;
            color: #fff;
          }
        }
      }

      &.is-attending .invitee-card--body--before {
        padding-bottom: 0;
      }

      &--body {
        padding-top: 15px;
      }
    }
  }
}
