$animation-duration: 400ms;
$animation-timing: cubic-bezier(0.5,0,0.1,1);

@keyframes ltr {
  from {
    transform: translateX(-460px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes rtl {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-460px);
  }
}

.about-carousel {
  position: relative;

  &--main {
    width: 100%;
    overflow: hidden;
    border-radius: 25px;
  }

  &--controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    button {
      display: inline-block;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0 15px;
      cursor: pointer;
      outline: none !important;

      &[disabled] {
        opacity: 0;
        pointer-events: none;
      }

      span {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        text-shadow: 0px 1px 4px rgba(0,0,0,0.4);
      }
    }
  }

  .animating-carousel {
    position: relative;
    width: 920px;

    &.direction-ltr {
      animation-name: ltr;
      animation-timing-function: $animation-timing;
      animation-duration: $animation-duration;
      transform: translateX(0px);
    }
    &.direction-rtl {
      animation-name: rtl;
      animation-timing-function: $animation-timing;
      animation-duration: $animation-duration;
      transform: translateX(-460px);
    }
  }

  .carousel-img {
    display: inline-block;
    width: 460px;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    @media screen and (max-width: 400px) {
      transform: translateX(-40px);
    }

    @media screen and (max-width: 300px) {
      transform: translateX(-100px);
    }
  }
}
