@import '../OrlandoVariables.scss';

.about-card {
  margin-top: 20px;
  padding: 15px 30px 15px;
  background: $gray-lightest;
  
  h2 {
    color: $color-text-dark-darker !important;
    margin-top: 15px !important;
  }
  p {
    color: $color-text-dark !important;
  }
}
