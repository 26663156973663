// Override default variables before the import
// $body-bg: #fff;

// Import Bootstrap and its default variables.
// @import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Lobster');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,700i');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700');
@import url('~normalize.css/normalize.css');

html {
  font-size: 10px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.input-group, input {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.toast {
  $toast-width: 300px;
  $toast-min-height: 40px;
  $toast-font-size: 11px;

  h1 {
    width: 100%;
  }

  @mixin toast-inner(
    $direction,
    $toast-offset-x,
    $toast-offset-y,
    $transform-start,
    $transform-end,
    $left,
    $margin-left,
    $margin-left-if-no-cssanimations,
    $top,
    $text-align
  ) {
    @keyframes toast-enter {
      from {
        transform: $transform-start;
        opacity: 0;
      }
      to {
        transform: $transform-end;
        opacity: 1;
      }
    }
  
    .cssanimations & {
      animation: toast-enter 200ms;
    }
    .no-cssanimations {
      // margin-left: $margin-left-if-no-cssanimations;
      margin-top: $toast-min-height + $toast-offset-y;
    }
  
    top: $top;
    left: $left;
    margin-left: $margin-left;
    transform: $transform-end;
    width: $toast-width;
    min-height: $toast-min-height;
    border-radius: $toast-min-height / 2;
    text-align: $text-align;
    
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
    box-sizing: border-box;
    padding: 5px 20px;
    color: #fff;
  
    &-type {
      &--success { background: #54ab12; }
      &--info { background: #1ca0c7; }
      &--danger { background: #ca4d4d; }
    }
  
    .cssanimations &.is-animating-out {
      transition: all 200ms;
      transform: $transform-start;
      opacity: 0;
    }
  
    h1 {
      font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
      text-transform: uppercase !important;
      font-size: $toast-font-size !important;
      line-height: $toast-font-size + 4px !important;
      font-weight: bold !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  @mixin toast($direction) {
    @if $direction == 'y' {
      $toast-offset-x: 0;
      $toast-offset-y: 20px;
      $transform-start: translateY(0);
      $transform-end: translateY($toast-min-height + $toast-offset-y);
      $left: 50%;
      $margin-left: -($toast-width / 2);
      $margin-left-if-no-cssanimations: auto; // No-op.
      $top: -$toast-min-height;
      $text-align: center;

      @include toast-inner(
        $direction,
        $toast-offset-x,
        $toast-offset-y,
        $transform-start,
        $transform-end,
        $left,
        $margin-left,
        $margin-left-if-no-cssanimations,
        $top,
        $text-align
      )
    }
    @if $direction == 'x' {
      $toast-offset-x: 20px;
      $toast-offset-y: 0;
      $transform-start: translateX(0);
      $transform-end: translateX(-($toast-width + $toast-offset-x));
      $left: 100%;
      $margin-left: auto; // No-op.
      $margin-left-if-no-cssanimations: -($toast-width + $toast-offset-x);
      $top: 20px;
      $text-align: left;

      @include toast-inner(
        $direction,
        $toast-offset-x,
        $toast-offset-y,
        $transform-start,
        $transform-end,
        $left,
        $margin-left,
        $margin-left-if-no-cssanimations,
        $top,
        $text-align
      )
    }
  }

  @include toast('y');
}

.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

fieldset {
  margin: 0; padding: 0 !important;
  border: none;
}

.preload-images {
  overflow: hidden;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -100;
}

button, button * {
  user-select: none;
}

button[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
